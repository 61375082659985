import { ref } from 'vue'
import { $api } from '@/api_client'
import { useInfoMessagesStore } from '@/store/infoMessages'

export function useFormManager (endpoint) {
	let url = endpoint
	url = url.slice(-1) === '/' ? url : url + '/'

	const errors = ref({})

	const messageStore = useInfoMessagesStore()

	const data = ref({})

	const loaded = ref(false)

	const getData = () => {
		return $api.get(url).then((res) => {
			data.value = res
			loaded.value = true
		})
	}

	const methods = (method, dataBody) => {
		errors.value = {}
		return new Promise((resolve, reject) => {
			$api(method, url, dataBody).then((res) => {
				messageStore.addSuccess('', 'Success')
				resolve()
			}).catch(err => {
				errors.value = err
				reject(err)
			})
		})
	}

	const patchData = (dataBody) => {
		return methods('patch', dataBody)
	}

	const putData = (dataBody) => {
		return methods('put', dataBody)
	}

	const postData = (dataBody) => {
		return methods('post', dataBody)
	}

	const deleteData = () => {
		return $api.delete(url).then((res) => {
			data.value = res
		})
	}

	return {
		data,
		errors,
		getData,
		patchData,
		putData,
		postData,
		deleteData,
		loaded
	}
}
