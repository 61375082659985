// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth'
function checkRole(){
	const authStore = useAuthStore()
	if(authStore.role != 'Deal Desk Expert'){
		router.push({ name: 'HourGlass' })
	}
}

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Login/'),
		meta: {
			title: 'Live INFUSE Login'
		}
	},
	{
		path: '/',
		name: 'HourGlass',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/HourGlass/'),
		meta: {
			title: 'Live INFUSE Dashboard'
		}
	},
	{
		path: '/mobile',
		name: 'Mobile',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Mobile/'),
		meta: {
			title: 'Live INFUSE Dashboard Mobile'
		}
	},
	{
		path: '/audience',
		name: 'Audience',
		component: () => import(/* webpackChunkName: "main" */ '@/pages/Audience/'),
		meta: {
			title: 'Live INFUSE Dashboard Audience'
		},
		beforeEnter: [checkRole],
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior () {
		return { top: 0 }
	}
})

router.beforeEach((to, from, next) => {
	const title = to.meta.title
	if (title) {
		document.title = title
	}
	next()
})

export default router
